<template>
  <form
    ref="form"
    @submit.prevent="sendCdpe"
  >
    <div>
      <h2 class="title">
        Informations générales
      </h2>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.code"
            v-validate="'required|min:3|max:50'"
            :readonly="editMode"
            :disabled="editMode"
            label="Code *"
            data-vv-name="code"
            :error-messages="errors.collect('code')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.nom"
            v-validate="'required|min:3|max:50'"
            :readonly="editMode"
            :disabled="editMode"
            label="Nom *"
            data-vv-name="nom"
            :error-messages="errors.collect('nom')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.site_web"
            v-validate="'max:255'"
            label="Site web"
            data-vv-name="site web"
            :error-messages="errors.collect('site web')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <DatePicker
            label="Date de prochain congrès"
            :date="dateProchainCongres"
            :readonly="true"
            :required="true"
            :between="{ after: now, before: limitDateProchainCongres, inclusion: '[]'}"
            @changeDate="changeCongresDate"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.numero_urgence"
            v-validate="'required|phoneNumber'"
            v-mask="'##.##.##.##.##'"
            label="Numéro d'urgence *"
            data-vv-name="numéro d'urgence"
            :error-messages="errors.collect(`numéro d'urgence`)"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.siren"
            v-validate="{ regex: /^([0-9]{9})$/ }"
            label="SIREN"
            data-vv-name="siren"
            :error-messages="errors.collect(`siren`)"
          />
        </v-col>
      </v-row>
    </div>
    <div>
      <h2 class="title">
        Adresse
      </h2>

      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.adresse_legale.ligne1"
            v-validate="'required|min:2|max:50'"
            label="Adresse postale *"
            data-vv-name="adresse postale"
            :error-messages="errors.collect('adresse postale')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.adresse_legale.ligne2"
            v-validate="'max:50'"
            label="Adresse complémentaire"
            data-vv-name="adresse postale complement"
            :error-messages="errors.collect('adresse postale complement')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <CommuneAutocomplete
            :loaded-commune="commune"
            :required="true"
            @changed="communeHasChanged"
          />
        </v-col>
      </v-row>
    </div>
    <div>
      <h2 class="title">
        Contact
      </h2>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.contact.telephone_fixe"
            v-validate="'phoneNumber'"
            v-mask="'##.##.##.##.##'"
            label="Téléphone fixe"
            data-vv-name="téléphone fixe"
            :error-messages="errors.collect('téléphone fixe')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.contact.telephone_mobile"
            v-validate="'phoneNumber'"
            v-mask="'##.##.##.##.##'"
            label="Téléphone portable"
            data-vv-name="téléphone portable"
            :error-messages="errors.collect('téléphone portable')"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="cdpe.contact.email"
            v-validate="'required|email'"
            label="Email *"
            data-vv-name="email"
            :error-messages="errors.collect('email')"
          />
        </v-col>
      </v-row>
    </div>
    <div>
      <h2 class="title">
        Contact secrétaire
      </h2>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="nomSecretaire"
            label="Nom secrétaire"
            @keydown.space.prevent
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="prenomSecretaire"
            label="Prénom secrétaire"
            @keydown.space.prevent
          />
        </v-col>
      </v-row>
    </div>
    <div>
      <h2 class="title">
        Commentaire
      </h2>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="cdpe.commentaire"
            :counter="255"
            append-icon="edit"
            label="Votre commentaire"
          />
        </v-col>
      </v-row>
    </div>
    <div>
      <h2 class="title">
        Horaires d'ouverture
      </h2>
      <v-dialog
        v-model="showHoraires"
        persistent
        max-width="500"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            class="mt-3"
            text
            v-on="on"
          >
            Modifier
          </v-btn>
        </template>
        <v-card>
          <div class="pa-5">
            <v-card-text> Horaires </v-card-text>
            <v-list>
              <v-list-item
                v-for="horaire in horaires"
                :key="horaire.label"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    {{ horaire.label }}
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="horaire.matin"
                      :readonly="horaire.immutable"
                      label="Matin"
                      @input="changeHoraire(horaire)"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="horaire.soir"
                      :readonly="horaire.immutable"
                      label="Soir"
                      @input="changeHoraire(horaire)"
                    />
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>

            <v-col cols="12">
              <v-textarea
                v-model="cdpe.horaires.commentaire"
                :counter="255"
                append-icon="edit"
                label="Votre commentaire"
              />
            </v-col>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="changeHoraires"
              >
                Fermer et enregistrer le cdpe
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="showHoraires = false"
              >
                Fermer
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <v-btn
      type="submit"
      :disabled="!formValid"
      class="mt-3"
      color="primary"
    >
      {{ editMode ? "Enregistrer ce cdpe": "Créer un cdpe" }}
      <v-icon
        right
        dark
      >
        edit
      </v-icon>
    </v-btn>
  </form>
</template>
<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
import DatePicker from '../partials/DatePicker';
import CommuneAutocomplete from '../partials/CommuneAutocomplete';

export default {
  name: 'CdpeForm',
  components: {
    DatePicker,
    CommuneAutocomplete,
  },

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    anneeScolaireCourante: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    showHoraires: false,
  }),

  computed: {
    ...mapState('cdpe/crud', [
      'cdpe',
    ]),

    ...mapGetters('cdpe/crud', {
      horaires: 'getHoraires',
    }),

    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },

    commune: {
      get() {
        return this.cdpe.adresse_legale.commune;
      },
      set(commune) {
        this.$store.commit('cdpe/crud/setCommune', commune);
      },
    },

    dateProchainCongres: {
      get() {
        return this.cdpe.date_prochain_congres;
      },
      set(date) {
        this.$store.commit('cdpe/crud/setDateProchainCongres', date);
      },
    },

    nomSecretaire: {
      get() {return this.$store.getters['cdpe/crud/getNomSecretaire'];
      },
      set(date) {
        this.$store.commit('cdpe/crud/setNomSecretaire', date);
      },
    },

    prenomSecretaire: {
      get() {
        return this.$store.getters['cdpe/crud/getPrenomSecretaire'];
      },
      set(date) {
        this.$store.commit('cdpe/crud/setPrenomSecretaire', date);
      },
    },

    now() {
      return (new Date()).toISOString().replace(/T.*/, '');
    },

    limitDateProchainCongres() {
      if (!this.anneeScolaireCourante || !this.anneeScolaireCourante.date_fin) {
        return '';
      }
      let d = new Date(this.anneeScolaireCourante.date_fin);
      d.setFullYear(d.getFullYear() + 1);
      return d.toISOString().replace(/T.*/, '');
    },
  },

  methods: {
    ...mapActions('cdpe/crud', {
      saveCdpe: 'save',
    }),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),

    changeCongresDate(date) {
      this.dateProchainCongres = date;
    },

    changeHoraires() {
      this.showHoraires = false;
      this.sendCdpe();
    },

    communeHasChanged(val) {
      this.commune = val;
    },

    changeHoraire(horaire) {
      this.$store.commit('cdpe/crud/setHoraire', horaire);
    },

    sendCdpe() {
      let cdpe = {
        adresse_legale: {
          ligne1: this.cdpe.adresse_legale.ligne1,
          ligne2: this.cdpe.adresse_legale.ligne2,
          commune_id: this.commune.id,
        },
        commentaire: this.cdpe.commentaire,
        contact: {
          email: this.cdpe.contact.email,
          telephone_fixe: this.cdpe.contact.telephone_fixe,
          telephone_mobile: this.cdpe.contact.telephone_mobile,
        },
        date_prochain_congres: this.cdpe.date_prochain_congres,
        horaires: this.cdpe.horaires,
        nom: this.cdpe.nom,
        numero_urgence: this.cdpe.numero_urgence,
        siren: this.cdpe.siren,
        secretaire: this.cdpe.secretaire,
        site_web: this.cdpe.site_web,
      };

      if (this.editMode) {
        this.saveCdpe({
          cdpe,
          cdpeId: this.cdpe.id,
        }).then(() => {
          this.setSuccess({ info: 'Le cdpe a bien été sauvegardé' });
        });
      }
    },
  },
};
</script>
