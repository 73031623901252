<template>
  <form
    ref="signataireForm"
    @submit.prevent="validate"
  >
    <h2 class="title">
      Signataire
    </h2>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <h3>Choix du responsable signataire</h3>
        <v-select
          v-model="responsableSelected"
          v-validate="'required'"
          no-data-text="Aucun responsable"
          :items="getResponsablesDepartementaux"
          item-value="id"
          :item-text="(item) => `${item.prenom} ${item.nom} - ${item.responsabilite? item.responsabilite.nom : ''}`"
          label="Responsable *"
          :loading="loading"
          required
          data-vv-name="responsable"
          :error-messages="errors.collect('responsable')"
          return-object
          single-line
          clearable
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <h3>Signature</h3>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-file-input
              v-model="image"
              v-validate="'required|size:1000|mimes:image/jpeg'"
              show-size
              data-vv-name="image"
              :error-messages="errors.collect('image')"
              accept="image/jpeg"
              label="Image de la signature *"
              :loading="loading"
              v-on="on"
            />
          </template>
          <ul>
            <li>Format de l'image : jpg</li>
            <li>Taille maximale du fichier : 1 Mo</li>
          </ul>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <img 
          v-if="srcImgSignature" 
          :src="`data:image/jpg;base64, ${srcImgSignature}`"
          width="200px"
          height="100px"
        >
      </v-col>
    </v-row>
    <v-btn
      large
      color="primary"
      type="submit"
      :disabled="!formValid"
      :loading="loading"
    >
      Enregistrer
      <v-icon
        right
        dark
      >
        edit
      </v-icon>
    </v-btn>
  </form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import xhr from '../../plugins/axios.js';

export default {
  name: 'SignataireForm',
  components: {
  },
  data: () => ({
    responsableSelected: {},
    image: null,
    srcImgSignature: '',
    loading: false,
  }),
  computed: {
    ...mapGetters('responsabilites', [
      'getResponsablesDepartementaux',
    ]),
    ...mapGetters('session', [
      'getUser',
    ]),
    ...mapState('cdpe/crud', [
      'cdpe',
    ]),
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },
  },
  watch: {
    getResponsablesDepartementaux(responsables) {
      if (!responsables) {
        return;
      }

      this.responsableSelected = responsables.find(resp => resp.is_signataire);
      if (this.responsableSelected) {
        xhr.get(`/api/admin/cdpe/${this.cdpe.id}/signature`, {
        responseType: 'arraybuffer',
        }).then((response) => {
          this.srcImgSignature = Buffer.from(response.data, 'binary').toString('base64');
        });
      }

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.errors.clear();
        });
      });
    },
  },
  methods: {
    ...mapActions('cdpe/crud', {
      uploadSignature: 'uploadSignature',
    }),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    validate() {
      this.$validator.validateAll();
      if (!this.formValid) {
        return;
      }

      const formData = new FormData();
      formData.append('loginUtilisateur', this.getUser.email);
      formData.append('perimetreCode', this.cdpe.code);
      formData.append('signataireId', this.responsableSelected.personne_id);
      formData.append('file', this.image);

      this.loading = true;

      this.uploadSignature(formData)
        .then(() => {
          this.loading = false;
          this.setSuccess({ info: 'Le signataire ont bien été sauvegardé' });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
