<template>
  <form
    ref="ahdesionEnLigneform"
    @submit.prevent="validate"
  >
    <h2 class="title">
      Adhésion en ligne
    </h2>
    <v-row>
      <v-col>
        <v-alert type="info">
          Les modifications seront actives sur le site d'adhésion en ligne dans un délai de 10 minutes
        </v-alert>
      </v-col>
    </v-row>  
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-switch
          v-model="adhesionenligne.actif"
          label="Actif"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <DatePicker
          label="Inactif à partir du"
          :date="adhesionenligne.periode_inactivite_start"
          :readonly="false"
          :required="false"
          @changeDate="changeInactifFrom"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <DatePicker
          label="Inactif jusqu'au"
          :date="adhesionenligne.periode_inactivite_end"
          :readonly="false"
          :required="false"
          @changeDate="changeInactifTo"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="adhesionenligne.additional_info"
          :counter="1000"
          label="Informations complémentaires"
          data-vv-name="additional-info"
          :error-messages="errors.collect('additional-info')"
          append-icon="edit"
        />
      </v-col>
    </v-row>
    <div class="mt-6 mb-6">
      <v-expansion-panels
        v-model="openPanels"
        accordion
        multiple
      >
        <v-expansion-panel
          key="paypal"
        >
          <v-expansion-panel-header>Paypal</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="adhesionenligne.paypal.actif"
              label="Actif"
            />

            <template v-if="adhesionenligne.paypal.actif">
              <v-text-field
                v-model="adhesionenligne.paypal.contact"
                label="Email compte"
                data-vv-name="paypal-contact"
                :error-messages="errors.collect('paypal-contact')"
              />
              <v-text-field
                v-model="adhesionenligne.paypal.technical.username"
                label="Username"
                data-vv-name="paypal-username"
                :error-messages="errors.collect('paypal-username')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.paypal.technical.password"
                label="Password"
                data-vv-name="paypal-password"
                :error-messages="errors.collect('paypal-password')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.paypal.technical.signature"
                label="Signature"
                data-vv-name="paypal-signature"
                :error-messages="errors.collect('paypal-signature')"
                readonly
                disabled
              />
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          key="hipay"
        >
          <v-expansion-panel-header>HiPay</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="adhesionenligne.hipay.actif"
              label="Actif"
            />

            <template v-if="adhesionenligne.hipay.actif">
              <v-text-field
                v-model="adhesionenligne.hipay.technical.login"
                label="Login compte"
                data-vv-name="hipay-login"
                :error-messages="errors.collect('hipay-login')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.password"
                label="Password"
                data-vv-name="hipay-password"
                :error-messages="errors.collect('hipay-password')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.website_id"
                label="Website ID"
                data-vv-name="hipay-websiteid"
                :error-messages="errors.collect('hipay-websiteid')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.category_id"
                label="Categorie ID"
                data-vv-name="hipay-categoryid"
                :error-messages="errors.collect('hipay-categoryid')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.rating"
                label="Rating"
                data-vv-name="hipay-rating"
                :error-messages="errors.collect('hipay-rating')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.locale"
                label="Locale"
                data-vv-name="hipay-locale"
                :error-messages="errors.collect('hipay-locale')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.ws_url_base"
                label="URL de base du WebService"
                data-vv-name="hipay-ws_url_base"
                :error-messages="errors.collect('hipay-ws_url_base')"
                readonly
                disabled
              />
              <v-text-field
                v-model="adhesionenligne.hipay.technical.validate_signature"
                label="Valider la signature"
                data-vv-name="hipay-validate_signature"
                :error-messages="errors.collect('hipay-validate_signature')"
                readonly
                disabled
              />
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          key="money-tigo"
        >
          <v-expansion-panel-header>OVRI</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="adhesionenligne.money_tigo.actif"
              label="Actif"
            />

            <template v-if="adhesionenligne.money_tigo.actif">
              <v-switch
                v-model="adhesionenligne.money_tigo.technical.compte_national"
                label="Utiliser le compte OVRI de la FCPE National"
                data-vv-name="money-tigo-compte-national"
                :disabled="!isUserNational"
                @change="clearMoneyTigo"
              />
              <template v-if="!adhesionenligne.money_tigo.technical.compte_national">
                <v-text-field
                  v-model="adhesionenligne.money_tigo.technical.login"
                  v-validate="{ required: adhesionenligne.money_tigo.actif && !adhesionenligne.money_tigo.technical.compte_national }"
                  label="Login compte"
                  data-vv-name="money-tigo-login"
                  :error-messages="errors.collect('money-tigo-login')"
                  :disabled="!isUserNational"
                />
                <v-text-field
                  v-model="adhesionenligne.money_tigo.technical.password"
                  v-validate="{ required: adhesionenligne.money_tigo.actif && !adhesionenligne.money_tigo.technical.compte_national }"
                  label="Password"
                  data-vv-name="money-tigo-password"
                  :error-messages="errors.collect('money-tigo-password')"
                  :disabled="!isUserNational"
                />
                <v-text-field
                  v-model="adhesionenligne.money_tigo.technical.merchant_key"
                  v-validate="{ required: adhesionenligne.money_tigo.actif && !adhesionenligne.money_tigo.technical.compte_national }"
                  label="Merchant Key"
                  data-vv-name="money-tigo-merchant-key"
                  :error-messages="errors.collect('money-tigo-merchant-key')"
                  :disabled="!isUserNational"
                />
                <v-text-field
                  v-model="adhesionenligne.money_tigo.technical.secret_key"
                  v-validate="{ required: adhesionenligne.money_tigo.actif && !adhesionenligne.money_tigo.technical.compte_national }"
                  label="Secret Key"
                  data-vv-name="money-tigo-secret-key"
                  :error-messages="errors.collect('money-tigo-secret-key')"
                  :disabled="!isUserNational"
                />
              </template>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          key="cheque"
        >
          <v-expansion-panel-header>Chèque</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="adhesionenligne.cheque.actif"
              label="Actif"
            />

            <template v-if="adhesionenligne.cheque.actif">
              <v-text-field
                v-model="adhesionenligne.cheque.ordre"
                v-validate="{ required: adhesionenligne.cheque.actif }"
                label="Ordre chèque"
                data-vv-name="cheque.ordre"
                :error-messages="errors.collect('cheque.ordre')"
              />
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel key="virement">
          <v-expansion-panel-header>Virement</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
              v-model="adhesionenligne.virement.actif"
              label="Actif"
            />

            <template v-if="adhesionenligne.virement.actif">
              <v-text-field
                v-model="adhesionenligne.virement.iban"
                v-validate="{ required: adhesionenligne.virement.actif }"
                label="IBAN"
                data-vv-name="virement.iban"
                :error-messages="errors.collect('virement.iban')"
              />
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-btn
      large
      color="primary"
      type="submit"
      :disabled="!formValid"
    >
      Enregistrer
      <v-icon
        right
        dark
      >
        edit
      </v-icon>
    </v-btn>
  </form>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import DatePicker from '../partials/DatePicker';
import Vue from 'vue';

export default {
  name: 'AdhesionenligneForm',
  components: {
    DatePicker,
  },
  data: () => ({
    openPanels: [],
  }),
  computed: {
    ...mapState('cdpe/adhesionenligne', [
      'adhesionenligne',
    ]),
    ...mapState('cdpe/crud', [
      'cdpe',
    ]),
    ...mapGetters('session', [
        'isUserNational',
    ]),
    formValid() {
      // loop over all contents of the fields object and check if they exist and valid.
      return Object.keys(this.fields).every(field => {
        return this.fields[field] && this.fields[field].valid;
      });
    },
  },
  watch: {
    adhesionenligne(adhesionenligne) {

      if (!adhesionenligne) {
        Vue.nextTick(() => {
          Vue.nextTick(() => {
            this.errors.clear(); // vee-validate reset
          });
        });

        return;
      }

      if (adhesionenligne.paypal.actif) {
        this.openPanels.push(0);
      }
      if (adhesionenligne.hipay.actif) {
        this.openPanels.push(1);
      }
      if (adhesionenligne.money_tigo.actif) {
        this.openPanels.push(2);
      }
      if (adhesionenligne.cheque.actif) {
        this.openPanels.push(3);
      }
      if (adhesionenligne.virement.actif) {
        this.openPanels.push(4);
      }

      // Needs to be done in a next tick otherwise depending on component depth, validation will
      // be executed before the value of the field has changed
      Vue.nextTick(() => {
        this.$validator.validateAll(); // vee-validate reset
      });

    },
  },
  methods: {
    ...mapActions('cdpe/adhesionenligne', {
      saveCdpeadhesionenligne: 'save',
    }),
    ...mapActions('session', {
      setSuccess: 'setSuccess',
    }),
    changeInactifFrom(date) {
      this.adhesionenligne.periode_inactivite_start = date;
    },
    changeInactifTo(date) {
      this.adhesionenligne.periode_inactivite_end = date;
    },
    validate() {
      this.$validator.validateAll();
      if (!this.formValid) {
        return;
      }

      this.saveCdpeadhesionenligne({ adhesionenligne: this.adhesionenligne, cdpeId: this.cdpe.id } )
        .then(() => {
          this.setSuccess({ info: 'Les informations d\'adhésion en ligne ont bien été sauvegardées' });
        });
    },
    clearMoneyTigo() {
        this.adhesionenligne.money_tigo.technical.login = null;
        this.adhesionenligne.money_tigo.technical.password = null;
        this.adhesionenligne.money_tigo.technical.merchant_key = null;
        this.adhesionenligne.money_tigo.technical.secret_key = null;
    },
  },
};
</script>
