<template>
  <v-stepper
    v-model="step"
    value="1"
  >
    <v-stepper-header>
      <v-stepper-step
        class="subheading"
        editable
        complete
        step="1"
      >
        {{ editMode ? "Editer le CDPE" :"Créer un CDPE" }}
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        class="subheading"
        :editable="editMode"
        :complete="editMode"
        step="2"
      >
        Signataire
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        class="subheading"
        :editable="editMode"
        :complete="editMode"
        step="3"
      >
        Adhésion en ligne
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <CdpeForm
          :edit-mode="editMode"
          :annee-scolaire-courante="anneeScolaireCourante"
        />
      </v-stepper-content>
      <v-stepper-content step="2">
        <SignataireForm />
      </v-stepper-content>
      <v-stepper-content step="3">
        <AdhesionEnLigneForm />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>`
<script>
import { mapActions, mapGetters } from 'vuex';
import CdpeForm from '../../components/cdpe/Form';
import AdhesionEnLigneForm from '../../components/cdpe/AdhesionEnLigneForm';
import SignataireForm from '../../components/cdpe/SignataireForm';

export default {
  name: 'CdpeFormContainer',

  components: {
    CdpeForm,
    AdhesionEnLigneForm,
    SignataireForm,
  },

  data: () => ({
    step: 1,
  }),

  computed: {
    ...mapGetters('session', {
      userCdpeId: 'getUserCdpeId',
    }),

    ...mapGetters('parametrages/anneeScolaire', {
      anneeScolaireCourante: 'getAnneeScolaireCourante',
    }),

    editMode() {
      //TODO change this when you have a create cdpe screen
      return true;
    },
  },
  created() {
    if (!this.editMode) {
      return;
    }

    let cdpeId = this.$route.params.cdpeId || this.userCdpeId;
    this.loadCdpeById({ cdpeId }).then(response => {
      this.$store.commit('cdpe/crud/setCurrentCdpe', response.data);
    });
    this.loadFilterAnneesScolaires();
    this.loadResponsabilitesDepartemental(cdpeId);
    this.loadCdpeAdhesionEnLigneByCdpeId({ cdpeId });

  },

  methods: {
    ...mapActions('cdpe', {
      loadCdpeById: 'loadById',
    }),
    ...mapActions('cdpe/adhesionenligne', {
      loadCdpeAdhesionEnLigneByCdpeId: 'loadByCdpeId',
    }),
    ...mapActions('responsabilites', [
      'loadResponsabilitesDepartemental',
    ]),
    ...mapActions('parametrages/anneeScolaire', [
      'loadFilterAnneesScolaires',
    ]),
  },
};
</script>